import { createTheme, ThemeProvider } from '@mui/material';
import { Auth } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ApplicationConfig } from './app.config';
import { SnackbarProvider } from 'notistack';
import './index.css';

Auth.configure(ApplicationConfig.aws);

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071b3'
    },
    secondary: {
      main: '#fff'
    },
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <BrowserRouter>
        <App/> 
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root') as HTMLElement, 
);

