import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'use-http';
import { ApplicationConfig } from './app.config';
import { AuthProvider } from './modules/auth/contexts/auth.context';
import { useAuth } from './modules/auth/hooks/use-auth.hook';

const Menu = React.lazy(() => import('./modules/menu/Menu'));
const Landing = React.lazy(() => import('./screens/Landing'));
const NirbbyUserRouter = React.lazy(() => import('./modules/shared/routers/NirbbyUserRouter'));

export default function App() {
  const [_, dispatchers] = useAuth();

  return (
    <AuthProvider value={dispatchers}>
      <Provider url={ApplicationConfig.url}>
        <Suspense fallback={null}>
          {
            ApplicationConfig.isCustomSubdomain ? (
              <Switch>
                <Route path="/u">
                  <NirbbyUserRouter />
                </Route>
                <Route path="/">
                  <Menu />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route path="/u" component={NirbbyUserRouter} />
                <Route path="/p/:slug" component={Menu} />
                <Route path="/:place" component={Menu} />
                <Route path="*" component={Landing} />
              </Switch>
            )
          }
        </Suspense>
      </Provider>
    </AuthProvider>
  );
}